import PropTypes from 'prop-types'
import React from 'react'
// import { connect } from 'react-redux'
import { getStore } from '@helpers/store-locator'
import DistributionCenterPopup from './distribution-center-popup'

class DistributionCenterPopupWrapper extends React.Component {
  state = {
    open: false,
  }

  togglePopup = e => {
    e.preventDefault()
    this.setState(prevState => ({
      open: !prevState.open,
    }))
  }

  render() {
    const { order } = this.props
    const { open } = this.state
    const store = getStore(order)
    return <DistributionCenterPopup togglePopup={this.togglePopup} store={store} open={open} />
  }
}

DistributionCenterPopupWrapper.propTypes = {
  order: PropTypes.any,
}

export default DistributionCenterPopupWrapper
