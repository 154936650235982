import React from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress, Grid, Divider, Stack, Typography, styled } from '@mui/material'
import CondensedContact from '@components/shared/condensed-contact-links'
import ProductLineItem from '../../../molecules/ProductLineItem/ProductLineItem'
import PriceAtom from '../../../atoms/ProductPrice/PriceAtom'
import TotalItems from './TotalItems'

const DesktopSummaryContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '0px 15px',
  margin: 0,
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '26px',
  color: theme.palette.primary.dark,
  paddingTop: '9px',
}))

const StyledDividerGrid = styled(Grid)(() => ({
  paddingTop: 15,
  paddingBottom: 15,
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  height: '1px',
  margin: '5px 0',
}))

const StyledHeading = styled(Typography)(({ theme }) => ({
  fontSize: '23px',
  fontWeight: 500,
  lineHeight: '31px',
  color: theme.palette.primary.dark,
  textTransform: 'uppercase',
}))

const DesktopOrderSummary = ({ dtIdPrefix, loading, missingDataMessage, productLineItems, total, totalLineItems }) => (
  <DesktopSummaryContainer direction="column" justifyContent="center" data-testid={`${dtIdPrefix}container`}>
    <StyledTitle>Order Summary</StyledTitle>
    <StyledDivider />
    {loading ? (
      <Box sx={{ display: 'flex', height: '300px', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress size="2rem" />
      </Box>
    ) : (
      <>
        <Stack sx={{ maxHeight: '540px', overflow: 'auto', padding: '5px 12px 15px 0' }} spacing="6px">
          {productLineItems?.map(
            ({ id, title, image, price, protectionPlanPrice, protectionPlanSelected, quantity, addOns, savings }) => (
              <ProductLineItem
                key={id}
                image={image}
                title={title}
                price={price}
                quantity={quantity}
                protectionPlanPrice={protectionPlanPrice}
                protectionPlanSelected={protectionPlanSelected}
                addOns={addOns}
                dtIdPrefix={dtIdPrefix}
                savings={savings}
              />
            ),
          )}
        </Stack>
        <StyledDividerGrid>
          <Divider />
        </StyledDividerGrid>

        <TotalItems totalLineItems={totalLineItems} missingDataMessage={missingDataMessage} dtIdPrefix={dtIdPrefix} />

        <StyledDividerGrid>
          <Divider />
        </StyledDividerGrid>
        <Stack direction="row" justifyContent="space-between" pb="15px">
          <StyledHeading data-testid={`${dtIdPrefix}title`}>{`Total${missingDataMessage ? '*' : ''}`}</StyledHeading>
          <StyledHeading data-testid={`${dtIdPrefix}total-price`}>
            <PriceAtom suppressCentsIfZero price={total} />
          </StyledHeading>
        </Stack>
        <StyledDivider />
        <CondensedContact />
      </>
    )}
  </DesktopSummaryContainer>
)

DesktopOrderSummary.propTypes = {
  dtIdPrefix: PropTypes.string,
  loading: PropTypes.bool,
  missingDataMessage: PropTypes.string,
  productLineItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      protectionPlanSelected: PropTypes.bool,
      protectionPlanPrice: PropTypes.number,
      addOns: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          price: PropTypes.number,
        }),
      ),
    }),
  ),
  total: PropTypes.number.isRequired,
  totalLineItems: PropTypes.arrayOf(
    PropTypes.shape({
      isDiscount: PropTypes.bool,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      subheading: PropTypes.string.isRequired,
    }),
  ),
}

export default DesktopOrderSummary
