import React from 'react'
import { Button, Box, styled } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { ButtonLink } from '@templates/strapi-cms/content-types/Link'

const ReturnToCartContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  a: {
    justifyContent: 'left',
  },
  svg: {
    color: theme.palette.primary.main,
  },
}))

const CartButton = styled(Button)({
  fontSize: '14px',
  width: '50%',
  justifyContent: 'right',
  lineHeight: 'normal',
})

const ReturnToCart = () => (
  <div className="cell small-12 checkout-page-header">
    <ReturnToCartContainer>
      <ChevronLeftIcon />
      <CartButton
        component={ButtonLink}
        data-testid="cartButton"
        data={{
          InternalUrl: '/cart',
          title: 'Return to cart',
        }}
        trackingData={{ category: 'header', action: 'return to cart click', label: 'return to cart' }}
      >
        Return to Cart
      </CartButton>
    </ReturnToCartContainer>
  </div>
)

export default ReturnToCart
