import React, { useState } from 'react'
import { func, array, string, object, bool, number } from 'prop-types'
import { useOrderSummary } from '@components/cart/useOrderSummary'
import SubmitButton from './submit-button'
import DatePicker from './date-picker'
import { StyledPopover, DateWrapper } from './styles'
/**
 * DateModal
 * Creates a modal with a slider presenting date ranges for selection
 */
function DateModal({ buttonText, closeModal, currentRef, dateRange, initialDate, isOpen, isPickup, onSubmit }) {
  const [selectedDate, setSelectedDate] = useState(initialDate)
  const { total, expressFee } = useOrderSummary()

  if (!isOpen) return null

  const handleSubmit = async date => {
    await onSubmit(date)
    setSelectedDate(null)
  }

  return (
    <>
      <StyledPopover
        sx={{ boxShadow: 0 }}
        open={isOpen}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        onClose={closeModal}
        anchorEl={currentRef?.current}
      >
        <DateWrapper autoFocus>
          <DatePicker
            dates={dateRange}
            isPickup={isPickup}
            expressFee={expressFee}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />

          <SubmitButton buttonText={buttonText} onSubmit={handleSubmit} selectedDate={selectedDate || initialDate} />
        </DateWrapper>
      </StyledPopover>
    </>
  )
}

DateModal.propTypes = {
  buttonText: string,
  closeModal: func,
  currentRef: object,
  dateRange: array,
  initialDate: object,
  isOpen: bool,
  isPickup: bool,
  onSubmit: func,
}

export default DateModal
