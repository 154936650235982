import React, { useMemo } from 'react'
import { any, object, objectOf, arrayOf, string } from 'prop-types'
import { styled, Grid, Typography } from '@mui/material'
import { getSplitShipmentData } from '@helpers/checkout/delivery-section'
import SplitDelivery from './delivery-section-split-option'
import SingleDelivery from './delivery-section-single-option'
import NonRTGDeliveryTile from './delivery-section-non-rtg-option'

const DeliveryTitle = styled(Typography)`
  font-size: 19px;
  font-weight: 500;
`

const DeliveryCondensed = ({ cart = {}, defaultDeliveryInterval, nonRTGDeliveries, order, rtgDeliveryItems }) => {
  const isSplitDelivery = !!order?.splitDeliveryDates
  const shipmentsData = !isSplitDelivery ? [] : getSplitShipmentData(cart.cartItems, order)

  const hasStoreCartItems = order?.lineItems?.filter(lineItem => lineItem?.isStoreSku)?.length > 0 ?? false
  const hasDeliveryItems = rtgDeliveryItems.length > 0 && (cart?.cartItems?.length > 0 || hasStoreCartItems)
  const hasVendorDeliveryItems = nonRTGDeliveries?.length > 0
  const isVendorDeliveryOnly = !hasDeliveryItems && hasVendorDeliveryItems

  const splitCalendarLength = order?.splitCalendar?.length ?? 0

  const selectedDeliveryModeString = useMemo(() => {
    if (order.isPickup) return 'Self-Pickup'
    if (order.doorwayDelivery) return `${order.isExpress ? 'Express ' : ''}Doorway Delivery`
    return `${order.isExpress ? 'Express ' : ''}Premium Delivery and Set-Up`
  }, [order])

  const deliveryCount = useMemo(() => {
    const count = nonRTGDeliveries?.length ?? 0
    if (isVendorDeliveryOnly) return count

    return isSplitDelivery ? count + splitCalendarLength : count + 1
  }, [isSplitDelivery, isVendorDeliveryOnly, nonRTGDeliveries, splitCalendarLength])

  const computeDeliveryIndex = index => {
    if (isVendorDeliveryOnly) return index + 1
    return isSplitDelivery ? splitCalendarLength + index + 1 : 2 + index
  }

  return (
    <Grid container>
      {!isVendorDeliveryOnly && (
        <>
          {isSplitDelivery ? (
            <SplitDelivery
              cart={cart}
              deliveryCount={deliveryCount}
              deliveryOptionString={selectedDeliveryModeString}
              isDeliveryEditable={false}
              order={order}
              shipmentsData={shipmentsData}
            />
          ) : (
            <SingleDelivery
              cartItems={cart?.cartItems ?? []}
              defaultDeliveryInterval={defaultDeliveryInterval}
              deliveryCount={deliveryCount}
              deliveryOptionString={selectedDeliveryModeString}
              isDeliveryEditable={false}
              order={order}
              rtgDeliveryItems={rtgDeliveryItems}
            />
          )}
        </>
      )}

      {nonRTGDeliveries.map(({ delivery, type }, index) => (
        <NonRTGDeliveryTile
          key={`${type}_items`}
          deliveryItems={delivery}
          cartItems={cart.cartItems ?? []}
          type={type}
          deliveryCount={deliveryCount}
          deliveryIndex={computeDeliveryIndex(index)}
        />
      ))}

      {!order.isPickup && order.additionalDirections && !isVendorDeliveryOnly && (
        <Grid item xs={12} mb={2} pl={{ xs: 0, sm: '6px' }}>
          <DeliveryTitle mb="5px">Delivery Instructions:</DeliveryTitle>
          <Typography>{`${(order.additionalDirections[0] ?? '').toUpperCase()}${order.additionalDirections.slice(1) ??
            ''}`}</Typography>
        </Grid>
      )}
    </Grid>
  )
}

DeliveryCondensed.propTypes = {
  cart: objectOf(any),
  defaultDeliveryInterval: string,
  nonRTGDeliveries: arrayOf(object),
  order: objectOf(any),
  rtgDeliveryItems: arrayOf(object),
}

export default DeliveryCondensed
