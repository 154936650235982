import React, { useState } from 'react'
import { bool, func, object } from 'prop-types'
import classNames from 'classnames'
import { Dialog } from '@material-ui/core'

import rtgCredit from '@assets/images/co-synchrony.svg'
import AlertModal from '@shared/alert-modal'
import PaymentTypeButton from './payment-type-button'
import RoomsToGoCredit from './rooms-to-go-credit'

const PaymentTypeSynchrony = ({ closeModals, isOpen, openModal, order }) => {
  const [hideModal, setHideModal] = useState(false)
  const [alertModal, setAlertModal] = useState({ alertMessage: '', closeModalFunction: null })
  const hasSynchronyApplied = order?.paymentInfo?.some(({ paymentType }) => paymentType === 'DBUY')
  const hasAlert = !!alertModal.alertMessage

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      closeModals()
      setHideModal(false)
    }
  }

  const closeAlertModal = (showRtgModal = true) => {
    if (showRtgModal && hideModal) setHideModal(false)

    setAlertModal({ alertMessage: '', closeModalFunction: null })
  }

  const showAlertModal = (msg, closeFn) => {
    setAlertModal({
      alertMessage: msg || "We're unable to process this payment, please try again.",
      closeModalFunction: closeFn ?? null,
    })
  }

  return (
    <>
      <div>
        <PaymentTypeButton
          isApplied={hasSynchronyApplied}
          isFullLogo
          ariaId="payment-option-synchrony"
          gtmLabel="Rooms To Go Finance"
          handleClick={() => openModal('finance')}
          label="Financing"
          startIcon={<img src={rtgCredit} width="175px" height="50px" alt="Rooms To Go Credit Card by Synchrony" />}
        />
        <Dialog
          onClose={handleClose}
          aria-labelledby="payment-option-synchrony"
          open={isOpen}
          tabIndex="0"
          id="payment-modal-synchrony"
          className={classNames('rooms-to-go-credit-container', { hide: hideModal })}
          fullWidth
          maxWidth="xs"
          PaperProps={{ style: { margin: '12px', width: 'calc(100% - 24px)' } }}
        >
          <RoomsToGoCredit
            closeRtgCreditModal={handleClose}
            hideAlertModal={closeAlertModal}
            hideRtgCreditModal={setHideModal}
            order={order}
            showAlertModal={showAlertModal}
          />
        </Dialog>
      </div>
      {hasAlert && (
        <AlertModal
          label="Digital Buy Modal"
          btnClass="dbuy-alert-close-btn"
          mdlClass="dbuy-alert-modal"
          shouldShowModal={hasAlert}
          closeModal={alertModal.closeModalFunction}
        >
          <div style={{ margin: '16px' }}>{alertModal.alertMessage}</div>
        </AlertModal>
      )}
    </>
  )
}

PaymentTypeSynchrony.propTypes = {
  closeModals: func,
  isOpen: bool,
  openModal: func,
  order: object,
}

export default PaymentTypeSynchrony
