import React, { useState } from 'react'
import { bool, number, object } from 'prop-types'
import { useDispatch } from 'react-redux'
import { Stack, Typography } from '@mui/material'

import { getDeliverySpecificBody } from '@helpers/checkout/delivery-section'
import { DoorwayDeliveryMessage } from '@helpers/delivery'
import { updateDeliveryAnalytics } from '@helpers/google-tag-manager'
import { sentryLogger, levels, sentryMessages, setExtra } from '@helpers/sentry-logger'
import { getStore } from '@helpers/store-locator'
import { useTest } from '@hooks/useTest'
import { setOrder, setDeliveryMode } from '@redux/modules/checkout'
import { updateDelivery } from '@services/checkout'

import { PROFESSIONAL_DELIVERY, PICK_UP, DOORWAY_DELIVERY } from './constants'
import { SelfPickupContent, PremiumDeliveryContent, DoorwayDeliveryContent } from './delivery-options'
import DeliveryOptionCard from './delivery-option-card'

const selectedDeliveryMode = (isPickup, isDoorway) => {
  if (isPickup && !isDoorway) return PICK_UP
  if (!isPickup && isDoorway) return DOORWAY_DELIVERY
  return PROFESSIONAL_DELIVERY
}

const DeliveryOptions = ({
  defaultNonSplitDates = {},
  defaultSplitDates,
  distanceToStore,
  earliestDeliveryDates,
  hasDeliveryMode,
  includesExpressFee,
  isMobile,
  order,
  splitShipmentDates,
  ...splitOptionProps // {isSplitDelivery, showSplitDeliveryOptionPicker, toggleSplit}
}) => {
  const initialDeliveryType = hasDeliveryMode ? selectedDeliveryMode(order.isPickup, order.doorwayDelivery) : null
  const [error, setError] = useState(false)
  const [deliveryType, setDeliveryType] = useState(initialDeliveryType)
  const [nextDeliveryType, setNextDeliveryType] = useState(undefined)
  const dispatch = useDispatch()
  const { generateTestId } = useTest()
  const dtIdPrefix = generateTestId('Checkout DeliveryOptions', '')
  const store = getStore(order) // This gets the RTG store, not redux store

  const showDoorwayOption = order?.doorwayDeliveryPossible && order?.doorwayDeliveryCharge >= 0
  const showPickupOption =
    !!store && order?.pickupCalendar?.length > 0 && distanceToStore != null && distanceToStore < 100

  let pickupLocation
  if (showPickupOption) {
    const address1 = `${store?.address1 ?? ''} ${store?.address2 ?? ''}`.trim().toLowerCase()
    const address2 = `${store?.city ?? ''}, ${store?.state ?? ''} ${store?.zip ?? ''}`
    pickupLocation = {
      distCenterName: `${store?.city ?? ''} Distribution Center`,
      phoneHref: `tel:${store?.phone?.replace(/\//g, '').replace(/-/g, '')}`,
      phoneDisplay: `${store?.phone?.replace('/', '-')}`,
      streetAddress: `${address1}, ${address2}`,
    }
  }

  const updateDeliveryMode = mode => {
    setNextDeliveryType(mode)
    const newOrder = {
      ...order,
      deliveryDate: order.splitDeliveryDates ? defaultSplitDates[mode] : defaultNonSplitDates[mode],
      doorwayDelivery: mode === DOORWAY_DELIVERY,
      isPickup: mode === PICK_UP,
    }
    if (order.splitDeliveryDates) {
      newOrder.splitDelivery = splitShipmentDates[mode]
    }

    updateDelivery(getDeliverySpecificBody(newOrder, mode, true))
      .then(data => {
        setDeliveryType(mode)
        setNextDeliveryType(undefined)
        dispatch(setOrder(data))
        dispatch(setDeliveryMode(mode))
        setError(false)
        updateDeliveryAnalytics(data)
      })
      .catch(err => {
        setNextDeliveryType(undefined)
        setError(true)
        sentryLogger({
          configureScope: {
            level: levels.error,
            orderId: order.orderId,
            text: `Update Delivery Modal Failure ${err}`,
            type: setExtra,
          },
          captureMessage: {
            level: levels.error,
            message: sentryMessages.updateDeliveryFailure,
            type: 'text',
          },
        })
      })
  }

  const premiumDeliveryFee =
    order?.tentativeDeliveryCharge +
      (deliveryType === PROFESSIONAL_DELIVERY && includesExpressFee ? order?.premiumExpressFee : 0) ||
    (order?.storeCart?.totalDeliveryCharge ?? 0)

  const isExpress = order.isExpress || order?.splitDeliveryDates?.some(d => d.expressDelivery)

  return (
    <>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={1.5}
        justifyContent="center"
        alignItems={{ xs: 'unset', md: 'flex-start' }}
        sx={{ paddingLeft: { xs: 0, md: '16px' }, paddingRight: { xs: 0, md: '16px' } }}
      >
        <DeliveryOptionCard
          deliveryFee={`$${premiumDeliveryFee}`}
          dtId={`${dtIdPrefix}premium`}
          isChecked={deliveryType === PROFESSIONAL_DELIVERY}
          isLoading={nextDeliveryType === PROFESSIONAL_DELIVERY}
          setDeliveryType={() => updateDeliveryMode(PROFESSIONAL_DELIVERY)}
          title="Premium"
        >
          <PremiumDeliveryContent
            additionalDirections={order.additionalDirections}
            availableDate={earliestDeliveryDates.delivery}
            dtId={`${dtIdPrefix}professionaldelivery`}
            expressFee={includesExpressFee ? order?.premiumExpressFee : 0}
            isChecked={deliveryType === PROFESSIONAL_DELIVERY}
            isDefault={order.defaultDelivery}
            isExpress={isExpress}
            isMobile={isMobile}
            expressCalendar={order.expressCalendar}
            {...splitOptionProps}
          />
        </DeliveryOptionCard>
        {showDoorwayOption && (
          <DeliveryOptionCard
            deliveryFee={`$${order?.doorwayDeliveryCharge}`}
            dtId={`${dtIdPrefix}${DOORWAY_DELIVERY}`}
            isChecked={deliveryType === DOORWAY_DELIVERY}
            isLoading={nextDeliveryType === DOORWAY_DELIVERY}
            setDeliveryType={() => updateDeliveryMode(DOORWAY_DELIVERY)}
            title={DoorwayDeliveryMessage.default}
          >
            <DoorwayDeliveryContent
              additionalDirections={order.additionalDirections}
              availableDate={earliestDeliveryDates.doorway}
              dtId={`${dtIdPrefix}doorwaydelivery`}
              expressFee={includesExpressFee ? order?.premiumExpressFee : 0}
              isChecked={deliveryType === DOORWAY_DELIVERY}
              isDefault={order.defaultDelivery}
              isExpress={isExpress}
              isMobile={isMobile}
              expressCalendar={order.expressCalendar}
              {...splitOptionProps}
            />
          </DeliveryOptionCard>
        )}
        {showPickupOption && (
          <DeliveryOptionCard
            deliveryFee="FREE"
            dtId={`${dtIdPrefix}${PICK_UP}`}
            isChecked={deliveryType === PICK_UP}
            isLoading={nextDeliveryType === PICK_UP}
            setDeliveryType={() => updateDeliveryMode(PICK_UP)}
            title="Self-Pickup"
          >
            <SelfPickupContent
              availableDate={earliestDeliveryDates.pickup}
              dtId={`${dtIdPrefix}selfpickup`}
              isChecked={deliveryType === PICK_UP}
              pickupLocation={pickupLocation}
              isMobile={isMobile}
              {...splitOptionProps}
            />
          </DeliveryOptionCard>
        )}
      </Stack>
      {error && (
        <Typography color="error" pl={{ xs: 0, md: '16px' }} mt={1}>
          There was an error updating your delivery options. Please try again.
        </Typography>
      )}
    </>
  )
}

DeliveryOptions.propTypes = {
  defaultNonSplitDates: object,
  defaultSplitDates: object,
  distanceToStore: number,
  earliestDeliveryDates: object,
  hasDeliveryMode: bool,
  includesExpressFee: bool,
  isMobile: bool,
  order: object,
  splitShipmentDates: object,
}

export default DeliveryOptions
