import React, { useEffect } from 'react'
import { bool, func, number, string } from 'prop-types'
import {
  styled,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import PriceAtom from '../../../../../@rtg2022/components/atoms/ProductPrice/PriceAtom'

const DeliveryTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: '18px',
  fontWeight: 500,
}))

const OptionLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: '16px',
  fontWeight: 400,
  '& .oneCardAmount': { color: '#07263B', marginLeft: 8 },
}))

const OptionSubLabel = styled(Typography)(() => ({
  color: 'rgba(51, 51, 51, 0.7)',
  fontSize: '12px',
  fontWeight: 400,
  marginLeft: '42px',
}))

const validatePriceInput = val => {
  // return true for empty string
  if (val === '') return true

  const validChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']

  // return false if first character is not 1 - 9
  if (val.length === 1) return validChars.slice(1, 10).includes(val)

  const valArr = val.split('')

  // return false if any character is not valid
  if (!valArr.every(i => validChars.includes(i))) return false

  // return false if '.' appears more than once
  if (valArr.filter(i => i === '.').length > 1) return false

  // return false if more than 2 characters appear after '.'
  if (valArr.includes('.')) {
    const pos = valArr.findIndex(x => x === '.')
    return pos >= valArr.length - 3
  }

  return true
}

const MultiTenderOption = ({
  amtDue,
  isFinalPayment,
  isSplit,
  splitAmount,
  testIdPrefix,
  toggleSplit,
  updateSplitAmt,
}) => {
  useEffect(() => {
    if (isFinalPayment) {
      updateSplitAmt(`${amtDue}`)
    }
  }, [amtDue, isFinalPayment, updateSplitAmt])

  const isPartialPayment = isSplit || isFinalPayment

  return (
    <FormControl sx={{ mb: '20px', width: 'fit-content' }} size="small">
      <FormLabel id="multi-tender-option-radio-buttons-group" sx={{ mb: '20px' }}>
        <DeliveryTitle> How would you like to pay? </DeliveryTitle>
      </FormLabel>
      <RadioGroup
        aria-labelledby="multi-tender-option-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={isPartialPayment ? 'split' : 'one'}
        onChange={e => toggleSplit(e.target.value === 'split')}
      >
        <FormControlLabel
          value="one"
          sx={{ ml: 0, mr: 0 }}
          control={<Radio disabled={isFinalPayment} />}
          label={
            <OptionLabel>
              One card payment:{' '}
              <PriceAtom
                bold
                className="oneCardAmount"
                price={amtDue}
                dataTestId={`${testIdPrefix}remaining-balance`}
              />
            </OptionLabel>
          }
        />
        <FormControlLabel
          value="split"
          sx={{ ml: 0, mr: 0 }}
          control={<Radio disabled={isFinalPayment} />}
          label={
            <Stack direction="row" spacing={1.25} alignItems="center">
              <OptionLabel> Split between cards: </OptionLabel>
              <TextField
                disabled={!isSplit || isFinalPayment}
                id="split-payment-amount"
                label="Enter Amount"
                onChange={e => {
                  if (validatePriceInput(e.target.value)) updateSplitAmt(e.target.value)
                }}
                value={splitAmount}
                size="small"
                sx={{
                  width: { xs: 138, md: 178 },
                  '& .MuiInputBase-input': { paddingLeft: '0 !important' },
                }}
                InputProps={{
                  startAdornment: isPartialPayment ? <InputAdornment position="start">$</InputAdornment> : null,
                  sx: { height: '38px' },
                }}
                style={{ paddingLeft: '0 !important' }}
              />
            </Stack>
          }
        />
      </RadioGroup>
      <OptionSubLabel>
        {isFinalPayment
          ? 'NOTE: The fourth applied payment must equal Remaining Balance'
          : 'Use up to 4 credit/debit cards'}
      </OptionSubLabel>
    </FormControl>
  )
}

MultiTenderOption.propTypes = {
  amtDue: number,
  isFinalPayment: bool,
  isSplit: bool,
  splitAmount: string,
  testIdPrefix: string,
  toggleSplit: func,
  updateSplitAmt: func,
}

export default MultiTenderOption
