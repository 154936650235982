import React from 'react'
import { any, arrayOf, string, number } from 'prop-types'
import { styled, css, Grid, Stack } from '@mui/material'
import { getProductDisplayImage } from '@helpers/product'
import { decodeHtml } from '@helpers/string-helper'
import MiniProductTile from './mini-product-tile'

const StyledDeliveryCount = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: 16px;
    font-weight: 400;
  `,
)

const StyledVendorName = styled('p')(
  ({ theme }) => css`
    color: ${theme.palette.primary.dark};
    font-size: 23px;
    font-weight: 600;
    margin: 5px 0;
    text-transform: capitalize;
  `,
)

const StyledShippingFee = styled('p')(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: 14px;
    font-weight: 400;
    b {
      font-weight: 600;
    }
  `,
)

const StyledAddon = styled(Stack)`
  p,
  span {
    font-size: 14px;
    font-weight: 400;
  }
  span {
    font-size: 12px;
  }
`

const shippedBy = {
  ups: ' Ships via UPS',
  usps: 'Ships via USPS',
  vendor: 'Shipping directly from vendor',
}

const NonRTGDeliveryTile = ({ cartItems, deliveryCount, deliveryIndex, deliveryItems, type }) => (
  <Grid item xs={12} md={6} pl={{ xs: 0, md: '15px' }} pr={{ xs: 0, md: '50px' }} pb="30px">
    {deliveryCount > 1 && (
      <StyledDeliveryCount>
        DELIVERY {deliveryIndex} OF {deliveryCount}
      </StyledDeliveryCount>
    )}
    <StyledVendorName>{shippedBy[type]}</StyledVendorName>
    <StyledShippingFee>
      <b>FREE</b> Doorway Delivery
    </StyledShippingFee>
    <Stack>
      {deliveryItems.map((item, i) => {
        const key = `${item.sku}_${i}`
        const itemInCart = cartItems.find(cartItem => cartItem.sku === item.sku)
        const product = itemInCart ? itemInCart.product : item
        return (
          <Stack key={key} sx={{ mt: '15px' }}>
            {product?.primary_image ? (
              <MiniProductTile
                href={itemInCart?.product?.route}
                image={getProductDisplayImage(itemInCart?.product ?? {})}
                isStoreSku={itemInCart?.product?.isStoreSku || false}
                quantity={itemInCart?.quantity}
                requiredAddon={itemInCart?.product?.required}
                requiresAssembly={product?.customer_assembly_required}
                selections={itemInCart?.selections}
                sku={product?.sku}
                title={product?.title}
              />
            ) : (
              <StyledAddon>
                <p>
                  {product.title ? decodeHtml(product.title) : `SKU: ${product.sku}`}{' '}
                  {item.warrantyEnabled && <span className="warranty-label">+Furniture Protection Plan</span>}
                </p>
                <span>QTY: {` ${item.quantity}`}</span>
                {/* {!_isEmpty(itemInCart?.selections) && renderSelections(selections, quantity)} */}
              </StyledAddon>
            )}
          </Stack>
        )
      })}
    </Stack>
  </Grid>
)

NonRTGDeliveryTile.propTypes = {
  cartItems: arrayOf(any),
  deliveryCount: number,
  deliveryIndex: number,
  deliveryItems: arrayOf(any),
  type: string,
}

export default NonRTGDeliveryTile
