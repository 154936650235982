import React from 'react'
import { element, func, string, bool } from 'prop-types'
import { Button, Stack, Typography, styled } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useTest } from '@hooks/useTest'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { savePageScroll } from '@helpers/product'
import { getOrder } from '@helpers/checkout/global'

const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'isFullLogo' && prop !== 'hasBottomBorder',
})(({ theme, hasBottomBorder, isFullLogo }) => ({
  minHeight: 75,
  borderRadius: 0,
  borderBottom: hasBottomBorder ? `1px solid ${theme.palette.divider}` : null,
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: '10px 0 10px 10px',
  '& .MuiButton-startIcon': {
    alignItems: 'center',
    marginRight: 20,
    minWidth: 175,
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 4,
    boxShadow: isFullLogo ? 'none' : '1px 1px 2px 0px #00000026',
    height: 63,
  },
}))

const ButtonLabel = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isApplied',
})(({ isApplied, theme }) => ({
  textAlign: 'left',
  width: '100%',
  color: '#333',
  textTransform: 'initial',
  fontWeight: isApplied ? 600 : 400,
  lineHeight: '18px',
  '& .secondaryLabel': {
    color: 'rgba(51, 51, 51, 0.7)',
    fontWeight: 400,
    letterSpacing: 'normal',
  },
  '& .greenLabel': { color: theme.palette.success.main },
}))

const scrollSavePaths = ['/furniture', '/mattress', '/search']

const PaymentTypeButton = ({ isApplied, ariaId, gtmLabel, handleClick, label, secondaryLabel, startIcon, ...rest }) => {
  const order = getOrder()
  const { generateTestId } = useTest()

  const onClick = () => {
    if (typeof window !== 'undefined') {
      addToDataLayer_nextgen('payment-type-open', { payment_type: gtmLabel })
      if (scrollSavePaths.includes(window?.location?.pathname)) savePageScroll()
    }
    handleClick()
  }

  const isGiftCard = gtmLabel.includes('Gift')

  const hasNoBalance = order?.paymentInfo?.length > 0 && order?.amountDue === 0
  const isDisabled = isGiftCard ? hasNoBalance : isApplied || hasNoBalance

  return (
    <StyledButton
      disabled={isDisabled}
      id={ariaId}
      data-testid={generateTestId('checkout', gtmLabel)}
      fullWidth
      onClick={onClick}
      startIcon={startIcon}
      endIcon={isApplied && <CheckCircleOutlineIcon sx={{ fontSize: '24px !important' }} color="success" />}
      // GOOGLE TAG MANAGER CONFIG
      gtm-category="checkout"
      gtm-action="payment-type-open"
      gtm-label={gtmLabel}
      // Overrides
      {...rest}
    >
      <ButtonLabel isApplied={isApplied} alignContent="center">
        <>
          <span>{label}</span>
          {secondaryLabel && (
            <Typography component="span" className="secondaryLabel" sx={{ fontSize: { xs: '12px', md: '14px' } }}>
              {secondaryLabel && secondaryLabel}
            </Typography>
          )}
        </>
      </ButtonLabel>
    </StyledButton>
  )
}

PaymentTypeButton.propTypes = {
  isApplied: bool,
  ariaId: string,
  gtmLabel: string,
  handleClick: func,
  label: string,
  secondaryLabel: string,
  startIcon: element,
}

export default PaymentTypeButton
