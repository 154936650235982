/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { object, string } from 'prop-types'
import styled from 'styled-components'
import _isEmpty from 'lodash/isEmpty'
import { styled as MuiStyled, Typography } from '@mui/material'
import { breakPoints, colors, fonts } from '@constants/styles'
import PriceAtom from '@rtg2022/components/atoms/ProductPrice/PriceAtom'
import { useOrderSummary } from '@components/cart/useOrderSummary'
import { BitpayPayment, DeliveryType, PaymentType, ReviewType, ShippingType } from './types'

const SectionContent = styled.div`
  &&& {
    padding: 0 16px 12px 16px;
    background: ${colors.primaryInvert};
    width: 100%;
    margin: 0;
    text-align: left;
    .btn-container {
      display: flex;
      justify-content: flex-end;
      a {
        width: 100%;
        &.bitpay {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: #333;
        }
        & > svg {
          margin-left: 0.7em;
        }
        & > span {
          text-align: end;
        }
      }
      @media only screen and (max-width: ${breakPoints.small}) {
        //position: sticky;
        bottom: 2.5rem;
        right: 0;
        z-index: 1;
        padding: 0.4rem 0.45rem 0 0.45rem;
        background: ${colors.primaryInvert};
        width: 100%;
        height: 4rem;
      }
      @media only screen and (max-width: ${breakPoints.medium}) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        position: sticky;
        bottom: 2.35rem;
        right: 0;
        z-index: 1;
        padding: 0.5em;
        background: ${colors.primaryInvert};
        width: 111%;
        margin: 0 -5.5%;
        text-align: center;
      }
      .loader {
        height: 2.75rem;
        @media only screen and (min-width: ${breakPoints['medium-min']}) {
          margin-right: 7%;
        }
      }
      .continue-btn,
      .place-order-btn {
        background-color: ${colors.primary};
        color: ${colors.primaryInvert};
        border-radius: 0;
        padding: 12px;
        text-align: center;
        box-sizing: border-box;
        font-size: ${fonts.txtMedium};
        text-transform: uppercase;
        font-weight: bold;
        width: 189px;
        /* position: relative;
        margin: 0 auto; */
        @media only screen and (min-width: ${breakPoints['medium-min']}) {
          right: 1.5%;
        }
        @media only screen and (max-width: ${breakPoints.medium}) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
          right: 0;
        }
        &:focus {
          outline: solid 3px -webkit-focus-ring-color;
        }
        &:hover {
          background-color: ${colors.lightBlue};
        }
        .loader {
          height: 1.688rem;
          margin: -1.2rem -1rem -1rem;
        }
      }
      .continue-btn {
        width: 10em;
      }
      .place-order-btn {
        width: 12em;
      }
      .paypal-buttons-layout-horizontal {
        width: 100% !important;
      }
    }
    .text-disclaimer {
      display: flex;
      flex-direction: row;
      padding: 16px;
      text-align: left;
      .daggers {
        margin-right: 5px;
      }
      .cell {
        flex: 1;
      }
      i {
        font-size: 0.7rem;
        font-style: italic;
      }
      p {
        i {
          text-decoration: underline;
        }
      }
    }
  }
`

const PaymentStepContainer = MuiStyled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
})

const RemainingBalance = MuiStyled(Typography, { shouldForwardProp: prop => prop !== 'isRemaining' })(
  ({ theme, isRemaining }) => ({
    marginRight: '2.5rem',
    color: isRemaining ? theme.palette.error.main : theme.palette.primary.dark,
    fontWeight: isRemaining ? 500 : 400,
    fontSize: '1rem',
  }),
)

const CheckoutStepAction = ({ checkoutStep, order }) => {
  const hasZeroSubtotal = !order.subtotal
  const { total, amtDue } = useOrderSummary()
  const [remaining, setRemaining] = useState(total)
  const { isMobile } = useSelector(state => state.global)

  useEffect(() => {
    /* Set remaining value to amountDue only when payment is applied */
    setRemaining(!_isEmpty(order.paymentInfo) ? amtDue : total)
  }, [amtDue, order, total])

  switch (checkoutStep) {
    case 'shipping':
      return hasZeroSubtotal ? null : <ShippingType />

    case 'delivery':
      return hasZeroSubtotal ? null : <DeliveryType />

    case 'payment':
      return (
        <PaymentStepContainer>
          <RemainingBalance isRemaining={remaining > 0}>
            Remaining Balance: <PriceAtom price={remaining} />
          </RemainingBalance>
          <PaymentType order={order} remaining={remaining} />
        </PaymentStepContainer>
      )

    case 'review': {
      if (isMobile) return null

      return order?.selectedPaymentType === 'bitpay' ? <BitpayPayment order={order} /> : <ReviewType />
    }

    default:
      return null
  }
}

CheckoutStepAction.propTypes = {
  checkoutStep: string,
  order: object,
}

const CheckoutStepContent = ({ checkoutStep, children, order, sectionType }) => (
  <SectionContent>
    {children}
    {checkoutStep === sectionType && <CheckoutStepAction checkoutStep={checkoutStep} order={order} />}
  </SectionContent>
)

CheckoutStepContent.propTypes = {
  checkoutStep: string,
  children: object,
  order: object,
  sectionType: string,
}

export default CheckoutStepContent
