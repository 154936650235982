import React from 'react'
import { bool, func, object } from 'prop-types'
import { Dialog } from '@material-ui/core'
import { useTest } from '@hooks/useTest'
import PaymentSvg from '@shared/svgs/paymentSvgComp'
import CreditCardMicroform from './credit-card-microform'
import PaymentTypeButton from './payment-type-button'

const PaymentTypeCreditCard = ({ closeModals, isOpen, openModal, order }) => {
  const { generateTestId } = useTest()
  const testIdPrefix = generateTestId('ccModal', '')
  const hasCreditCardApplied = order?.paymentInfo?.some(({ paymentType }) => paymentType === 'CYBERV3')

  return (
    <div>
      <PaymentTypeButton
        isApplied={hasCreditCardApplied} // TODO - this can be removed since component isn't rendered when hasCreditCardApplied === true
        ariaId="payment-option-credit"
        gtmLabel="Credit"
        handleClick={() => openModal('credit')} // TODO - would it be better to maintain isOpen state within this component?
        label="Credit or Debit"
        secondaryLabel="Use up to 4 credit/debit cards"
        startIcon={
          <PaymentSvg
            uniqueNameForId="creditMicroForm2"
            cards={['discover', 'mastercard', 'visa', 'amex']}
            cordY="0"
            vpHeight="50"
            width="175px"
            height="50px"
            style={{ padding: '0 7px' }}
          />
        }
      />
      <Dialog
        onClose={closeModals}
        aria-labelledby="payment-option-credit"
        open={isOpen}
        tabIndex="0"
        id="payment-modal-credit"
        className="credit-card-container"
        fullWidth
        PaperProps={{ style: { margin: '12px', width: 'calc(100% - 24px)', maxWidth: '500px' } }}
      >
        <CreditCardMicroform order={order} onClose={closeModals} testIdPrefix={testIdPrefix} />
      </Dialog>
    </div>
  )
}

PaymentTypeCreditCard.propTypes = {
  closeModals: func,
  isOpen: bool,
  openModal: func,
  order: object,
}

export default PaymentTypeCreditCard
