import React from 'react'
import { any, array, bool, object, string } from 'prop-types'
import _isEmpty from 'lodash/isEmpty'
import { Stack, styled } from '@mui/material'
import { decodeHtml } from '@helpers/string-helper'
import { productUrl } from '@helpers/route'
import { colors } from '@constants/styles'
import RoomItemList from '@shared/room-item-list'
import RTGLink from '@shared/link'

const ProductDetails = styled('div')({
  color: colors.darkerGrey,
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  'a, p': {
    fontSize: '14px',
    fontWeight: '400',
  },
  span: {
    fontSize: '12px',
  },
})

const ProductThumbnail = styled('div')({
  width: '120px',
  display: 'flex',
  justifyContent: 'center',
})

const ProductTitle = styled('span')({
  flex: 1,
})

const ProductLabel = styled('span')({
  marginRight: '5px',
})

const Quantity = styled('span')({
  fontSize: '12px',
  flex: '0 0 auto',
})

const SubItem = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: '15px',
})

const SelectionsItem = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '.4rem',
})

const StyledAssemblyRequired = styled('p')({
  fontSize: '12px',
  fontStyle: 'italic',
  fontWeight: '400',
  margin: '5px 0',
})

const MiniProductTile = ({
  href,
  image,
  isConfigurable = false,
  isStoreSku,
  items,
  packageSkuMap,
  quantity,
  requiredAddon,
  requiresAssembly = false,
  selections,
  sku,
  subItems,
  title,
}) => {
  const route = href ? productUrl(href) : null

  const isProductIsComplete = () => {
    const numberOfPieces = packageSkuMap ? Object.values(packageSkuMap).filter(value => value === sku).length : null
    return numberOfPieces === subItems.length
  }

  return (
    <Stack direction="row" mb="0.5rem" spacing={2}>
      {!requiredAddon && image && (
        <Stack justifyContent={!items || !items.length ? 'center' : 'flex-start'}>
          <RTGLink data={{ url: route, altDesc: title || '' }} aria-hidden="true" tabIndex="-1">
            <ProductThumbnail>
              <img
                src={`${image}${sku !== '83333333' && !isConfigurable ? '&h=90' : ''}`}
                alt={title}
                role="presentation"
                aria-hidden="true"
              />
            </ProductThumbnail>
          </RTGLink>
        </Stack>
      )}
      <ProductDetails>
        <RTGLink data={{ slug: route, altDesc: title || '' }} disabled={requiredAddon || isStoreSku}>
          <p>{title ? decodeHtml(title) : `SKU: ${sku} `}</p>
        </RTGLink>
        {(!subItems || subItems.length === 0 || isProductIsComplete()) && <Quantity>QTY: {` ${quantity}`}</Quantity>}
        {!_isEmpty(selections) && (
          <SelectionsItem key={selections.sku}>
            <ProductTitle>
              <ProductLabel>Foundation: {selections.title}</ProductLabel>
            </ProductTitle>
            {/* <Quantity>
              QTY:
              {` ${quantity * selections.quantity}`}
            </Quantity> */}
          </SelectionsItem>
        )}
        {requiresAssembly && <StyledAssemblyRequired>Assembly required </StyledAssemblyRequired>}

        {(!items || !items.length) && subItems && subItems.length > 0 && !isProductIsComplete() && (
          <span>
            Includes the following items from this package:{' '}
            {subItems.map(subItem => (
              <SubItem key={subItem.sku}>
                <ProductTitle>{subItem.title}</ProductTitle>
                <Quantity>QTY: {` ${quantity * subItem.quantity}`}</Quantity>
              </SubItem>
            ))}
          </span>
        )}
        {items && items.length > 0 && (
          <RoomItemList
            hideBorders
            packageSkuMap={packageSkuMap}
            fullWidth
            items={items}
            text="This delivery includes the following items from this room"
          />
        )}
      </ProductDetails>
    </Stack>
  )
}

MiniProductTile.propTypes = {
  href: any,
  image: any,
  isConfigurable: bool,
  isStoreSku: bool,
  items: array,
  packageSkuMap: any,
  quantity: any,
  requiredAddon: any,
  requiresAssembly: bool,
  selections: object,
  sku: string,
  subItems: array,
  title: string,
}

export default MiniProductTile
