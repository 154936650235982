/* eslint-disable no-restricted-globals */
import React, { useRef, useState } from 'react'
import { func } from 'prop-types'
import { setOrderInfo } from '@helpers/checkout/global'
import { validateRTGCreditInfo } from '@helpers/checkout/payment-section/rtg-finance'
import loaderLight from '@assets/images/loader-light.svg'
import { getFinancePlans } from '@helpers/finance'
import ErrorMessage from '@shared/error-message'
import { generateErrorMessage } from '@helpers/errors'
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DialogDivider, LoadingSpinner } from './styles'

const ConcoraCredit = ({ closeModal }) => {
  const cardNumberRef = useRef()
  const phoneRef = useRef()
  const termsRef = useRef()

  const [creditCardNumber, setCreditCardNumber] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [rtgCreditInfo, setRtgCreditInfo] = useState({ acknowledge: true, terms: false })
  const [invalidFields, setInvalidFields] = useState([])
  const [loading, setLoading] = useState(false)
  const [genesisAttempts, setGenesisAttempts] = useState(0)

  const financePlans = getFinancePlans(0, true)
  const genPlan = financePlans.find(plan => plan?.financeCode === 'GENESIS')

  const updateCreditCardNumber = value => {
    if (value) {
      if (value.length === 16 && value.startsWith('7')) {
        setOrderInfo({ code: 'GENESIS', hasPayment: true }, 'financePlan')
      }
      if (invalidFields.includes('finance')) setInvalidFields(current => current.filter(f => f !== 'finance'))
    }
    setRtgCreditInfo({ acknowledge: true, terms: false })
    return setCreditCardNumber(value)
  }

  const updatePhoneNumber = value => {
    if (value) {
      if (invalidFields.includes('phone')) setInvalidFields(current => current.filter(f => f !== 'phone'))
    }
    return setPhoneNumber(value)
  }

  const updateRtgCreditInfo = (info, field) => {
    if (invalidFields.includes('genesis-terms')) setInvalidFields(current => current.filter(f => f !== 'genesis-terms'))
    return setRtgCreditInfo(prevState => ({ ...prevState, [field]: info }))
  }

  const setRTGCreditState = (info, field = '', callback = null) => {
    if (!field) {
      Object.keys(info).forEach(f => {
        if (f === 'loading') setLoading(info[f])
        if (f === 'invalidFields') setInvalidFields(info[f])
      })
    } else {
      if (field === 'loading') setLoading(info)
      if (field === 'invalidFields') setInvalidFields(info)
      if (field === 'rtgCreditInfo') setRtgCreditInfo(info)
      if (field === 'genesisRemainingBalance') console.log({ info }) // eslint-disable-line
    }
    if (callback) callback()
  }

  const trimCreditCard = (card = '') => card.replace(/\s/g, '')
  const trimPhoneNumber = (num = '') => num.replace(/[-.()\s]/g, '')

  const credit = trimCreditCard(creditCardNumber)
  const phone = trimPhoneNumber(phoneNumber)

  const hasInvalidFields = () => {
    const newInvalidFields = []
    let attempts = genesisAttempts

    if (!rtgCreditInfo.terms) {
      newInvalidFields.push('genesis-terms')
    } else {
      attempts += 1
    }

    if (attempts === 3) {
      newInvalidFields.push('genesis-attempts')
    } else {
      if (isNaN(credit)) {
        newInvalidFields.push('financeAlphabetical')
      } else if (credit.length < 1) {
        newInvalidFields.push('financeEmpty')
      } else if (credit.length !== 16) {
        newInvalidFields.push('financeLength')
      }

      if (isNaN(phone)) {
        newInvalidFields.push('phoneAlphabetical')
      } else if (phone.length < 1) {
        newInvalidFields.push('phoneEmpty')
      } else if (phone.length !== 10) {
        newInvalidFields.push('phoneLength')
      }
    }

    if (newInvalidFields.length) {
      setInvalidFields(newInvalidFields)
    }

    setGenesisAttempts(attempts)
    return newInvalidFields.length > 0
  }

  const createErrorMessage = (field, index) => {
    const errorMessage = generateErrorMessage(field)
    return errorMessage
  }

  const planInfo = {
    cardNumber: trimCreditCard(creditCardNumber),
    phoneNumber: trimPhoneNumber(phoneNumber),
  }

  const handleClick = async e => {
    if (!hasInvalidFields()) {
      await validateRTGCreditInfo(e, genPlan, planInfo, setRTGCreditState, closeModal)
    }
  }

  const errorMsg = null
  const filteredFields = [
    'financeEmpty',
    'financeLength',
    'financeAlphabetical',
    'phoneEmpty',
    'phoneLength',
    'phoneAlphabetical',
    'phoneMatch',
  ]
  return (
    <>
      <DialogTitle id="payment-modal-genesis_title" component="div" sx={{ p: '24px 24px 12px 24px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" sx={{ fontSize: 19, fontWeight: 600, textTransform: 'capitalize' }}>
            Enter Concora Account
          </Typography>
          <Typography component="i" sx={{ color: '#E11F21', fontSize: 12, fontWeight: 400, fontStyle: 'italic' }}>
            * Required
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '8px' }}>
        {errorMsg && <ErrorMessage customMessage={{ message: errorMsg, id: 'finance-error' }} />}
        {invalidFields
          .filter(f => !filteredFields.includes(f))
          .map((field, index) => (
            <ErrorMessage key={field} customMessage={createErrorMessage(field, index)} invalidFields={[field]} />
          ))}

        <Stack gap={2}>
          <TextField
            value={creditCardNumber}
            disabled={genesisAttempts === 3}
            error={invalidFields?.some(field => field.includes('finance')) ?? false}
            FormHelperTextProps={{
              style: { fontSize: 12, fontWeight: 400 /* textWrap: isMobile ? 'wrap' : 'nowrap' */ },
            }}
            fullWidth
            helperText={
              invalidFields?.some(field => field.includes('finance'))
                ? `* ${createErrorMessage(invalidFields.find(field => field.includes('finance')))?.message ??
                    'Please enter a valid Account Number'}`
                : null
            }
            id="credit-card"
            inputRef={cardNumberRef}
            label={
              <>
                Account Number <span style={{ fontSize: 18, color: '#E11F21' }}>*</span>
              </>
            }
            onChange={e => updateCreditCardNumber(e.target.value)}
          />

          <TextField
            value={phoneNumber}
            disabled={genesisAttempts === 3}
            error={invalidFields?.some(field => field.includes('phone')) ?? false}
            FormHelperTextProps={{
              style: { fontSize: 12, fontWeight: 400 /* textWrap: isMobile ? 'wrap' : 'nowrap' */ },
            }}
            fullWidth
            helperText={
              invalidFields?.some(field => field.includes('phone'))
                ? `* ${createErrorMessage(invalidFields.find(field => field.includes('phone')))?.message ??
                    'Please enter a Phone Number Associated with your Concora Credit Account'}`
                : null
            }
            id="phone"
            inputRef={phoneRef}
            label={
              <>
                Phone Number <span style={{ fontSize: 18, color: '#E11F21' }}>*</span>
              </>
            }
            onChange={e => updatePhoneNumber(e.target.value)}
          />

          <FormControlLabel
            sx={{ alignItems: { xs: 'flex-start', sm: 'center' } }}
            control={
              <Checkbox
                checked={rtgCreditInfo.terms}
                disabled={genesisAttempts === 3}
                inputProps={{ 'aria-label': 'controlled' }}
                inputRef={termsRef}
                onChange={e => updateRtgCreditInfo(e.target.checked, 'terms')}
                sx={{ pt: { xs: '4px', sm: '9px' } }}
              />
            }
            label={
              <Typography sx={{ textAlign: 'left' }}>
                By checking this box I agree to finance my purchase using my Concora Credit Account.
              </Typography>
            }
          />
        </Stack>
      </DialogContent>
      <DialogDivider />
      <DialogActions sx={{ justifyContent: 'center', padding: '24px' }}>
        <Button fullWidth variant="outlined" onClick={!loading ? closeModal : null}>
          Cancel
        </Button>
        <Button
          sx={{ height: '40px' }}
          disabled={genesisAttempts === 3}
          fullWidth
          variant="contained"
          onClick={!loading ? handleClick : null}
        >
          {!loading && 'Submit'}
          {loading && <LoadingSpinner alt="Submitting rooms to go credit card" src={loaderLight} />}
        </Button>
      </DialogActions>
    </>
  )
}

ConcoraCredit.propTypes = {
  closeModal: func,
}

export default ConcoraCredit
